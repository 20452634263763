import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Modal } from 'bootstrap';
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = ['content', 'modal', 'inputImage'];
  static bsModal;
  static cropper;
  static fileName;
  static fileType;

  connect() {
    this.cropper = null;
  }

  initialize() {
    this.bsModal = new Modal(this.modalTarget);
  }

  show(e) {
    if (e.target.value !== '') {
      this.bsModal.show();
      const file = e.target.files[0];
      this.fileName = file.name;
      this.fileType = file.type;
      const blob = window.URL.createObjectURL(file);

      const img = document.createElement('img');
      img.setAttribute('src', blob);

      this.contentTarget.innerHTML = '';
      this.contentTarget.appendChild(img);

      this.cropper = new Cropper(img);

      const canvas = this.cropper.getCropperCanvas();
      canvas.style.height = '85vh';
    }
  }

  save() {
    const selection = this.cropper.getCropperSelection();
    selection.$toCanvas().then((canvas) => {
      canvas.toBlob((blob) => {
        // 表示している画像をクリップした画像に変更
        const parentElement = this.inputImageTarget.closest('div');
        parentElement.querySelector('img').setAttribute('src', window.URL.createObjectURL(blob));
        // Blob を元に File 化します。
        const croppedImgFile = new File([blob], this.fileName, { type: this.fileType });
        // DataTransfer インスタンスを介することで input 要素の files に
        // JavaScript 内で作った File を渡せます。
        // 直に new FileList から作って渡そうとすると失敗します。
        const dt = new DataTransfer();
        dt.items.add(croppedImgFile);
        this.inputImageTarget.files = dt.files;
      });
    });
    this.bsModal.hide();
  }
}
