import Rails from '@rails/ujs';

import 'controllers';
import '../japan_concierge/i18n/en';
import '../japan_concierge/i18n/fr';
import '../japan_concierge/i18n/id';
import '../japan_concierge/i18n/ja';
import '../japan_concierge/i18n/ko';
import '../japan_concierge/i18n/th';
import '../japan_concierge/i18n/vi';
import '../japan_concierge/i18n/zh-CN';
import '../japan_concierge/i18n/zh-TW';
import './server_rendering';

Rails.start();
